<template>
  <v-app id="inspire">
    <v-main>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-img src="@/assets/img/logo_color.png" class="mb-4" />

            <v-alert
              v-model="notification.show"
              type="error"
              transition="scale-transition"
              dismissible
            >
              {{ notification.message }}
            </v-alert>

            <Form @submit="login">
              <v-card class="elevation-12">
                <v-toolbar
                  color="primary"
                  dark
                  flat
                >
                  <v-toolbar-title>Recuperar contraseña</v-toolbar-title>
                </v-toolbar>

                <v-card-text>
                  <span v-if="passwordChanged">
                    La contraseña ha sido restablecida.
                  </span>

                  <template v-else>
                    <TextField
                      v-model="form.password"
                      label="Nueva Contraseña"
                      prepend-icon="mdi-lock"
                      rules="required"
                      vid="password"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                    />

                    <TextField
                      v-model="form.password_confirm"
                      label="Confirmar Contraseña"
                      prepend-icon="mdi-lock"
                      rules="required|confirmed:password"
                      :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPasswordConfirm ? 'text' : 'password'"
                      @click:append="showPasswordConfirm = !showPasswordConfirm"
                    />
                  </template>
                </v-card-text>

                <v-card-actions class="d-flex justify-center">
                  <v-btn
                    v-if="passwordChanged"
                    class="px-10 mb-3"
                    color="primary"
                    :to="{ name: 'login' }"
                  >
                    <v-icon left>mdi-login</v-icon>
                    Ir a inicio de sesión
                  </v-btn>

                  <v-btn
                    v-else
                    class="px-10 mb-3"
                    color="primary"
                    type="submit"
                  >
                    <v-icon left>mdi-key</v-icon>
                    Cambiar contraseña
                  </v-btn>
                </v-card-actions>
              </v-card>
            </Form>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// API
import AuthAPI from '@/api/auth';

export default {
  name: 'PasswordRecoveryReset',

  props: {
    token: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    form: {
      password: null,
      password_confirm: null,
    },
    notification: {
      show: false,
      message: null,
      type: 'error',
    },
    passwordChanged: false,
    showPassword: false,
    showPasswordConfirm: false,
  }),

  created() {
    this.api = new AuthAPI();
    this.form.token = this.token;
  },

  methods: {
    async login() {
      this.$store.commit('showLoader');
      try {
        await this.api.resetPassword(this.form);
        this.passwordChanged = true;
      } catch (error) {
        let message = 'Ocurrió un error. Por favor, vuelve a intentar.';

        if (error.status === 400) {
          message = 'Las contraseñas no coinciden';
        }

        if (error.data.token) {
          message = error.data.token.pop();
        }

        this.notification = {
          message,
          type: 'error',
          show: true,
        };
      }
      this.$store.commit('hideLoader');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
